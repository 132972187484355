import { Injectable } from '@angular/core';
import { HttpClient, HttpErrorResponse, HttpResponse } from '@angular/common/http';
import { ConverterService } from '@core/converter.service';
import { Observable, Subject } from 'rxjs';
import { Operation, OperationFilters } from '@models/operation-model';
import { isNilty } from '@core/utils.service';
import { OperationDetailsOutput, OperationRowFilters } from '@models/operation-row-model';
import { UploadFileOutput } from '@models/upload-file-output';
import { NewOperationInput } from '@models/new-operation-input';
import { flatMap, map } from 'rxjs/operators';
import { EnvironmentService } from '../../../environment.service';
import { OperationLabelInfoModel } from '@models/operation-label-info-model';

@Injectable()
export class OperationsService {
  operationFilters: OperationFilters;
  operationRowsFilters: OperationRowFilters;

  resultsNumber = new Subject<number>();
  expectedQuantity = new Subject<number>();
  receivedQuantity = new Subject<number>();

  constructor(
    private http: HttpClient,
    private converter: ConverterService,
    private environmentService: EnvironmentService,
  ) {}

  getOperationsByFilters(filters?: OperationFilters): Observable<Operation[]> {
    const body = this.converter.fromObjtoJSON(filters ? filters : this.operationFilters);
    return this.http.post(this.environmentService.getRestEndpoint('operations'), body, { observe: 'response' }).pipe(
      map((resp: HttpResponse<any>) => {
        this.resultsNumber.next(+resp.headers.get('Total-Length'));
        return resp.body;
      }),
      map((resp: any[]) => {
        if (!isNilty(resp)) {
          return resp.map((it) => this.converter.fromJSONtoObj(it, Operation));
        } else {
          return [];
        }
      }),
    );
  }

  getRowsByFilters(operationId: string): Observable<OperationDetailsOutput> {
    const endpoint = this.environmentService.getRestEndpoint('operationDataRows');
    const path = this.environmentService.dev ? endpoint : endpoint + '/' + operationId;
    const body = this.converter.fromObjtoJSON(this.operationRowsFilters);
    return this.http.post(path, body, { observe: 'response' }).pipe(
      map((resp: HttpResponse<any>) => {
        this.resultsNumber.next(+resp.headers.get('Total-Length'));
        this.expectedQuantity.next(+resp.headers.get('Expected-Quantity'));
        this.receivedQuantity.next(+resp.headers.get('Received-Quantity'));
        return resp.body;
      }),
      map((resp: OperationDetailsOutput) => {
        if (!isNilty(resp)) {
          return this.converter.fromJSONtoObj(resp, OperationDetailsOutput);
        } else {
          return null;
        }
      }),
    );
  }

  newOperation(file: File, operation: Operation): Observable<boolean> {
    const body = new FormData();
    body.append('file', file);
    const endpoint = this.environmentService.getRestEndpoint('uploadFile');
    const path = this.environmentService.dev ? endpoint : endpoint + '?type=' + operation.type.toLowerCase();

    return this.http.post(path, body).pipe(
      map((resp: UploadFileOutput) => {
        if (isNilty(resp) || isNilty(resp.url)) {
          return null;
        } else {
          return resp.url;
        }
      }),
      flatMap((url: string) => {
        if (!isNilty(url)) {
          return this.http.post(
            this.environmentService.getRestEndpoint('newOperation'),
            this.converter.fromObjtoJSON(new NewOperationInput(operation, url)),
            {
              observe: 'response',
            },
          );
        } else {
          return null;
        }
      }),
      map((resp: HttpResponse<any>) => {
        if (isNilty(resp)) {
          return false;
        } else {
          return !(event instanceof HttpErrorResponse);
        }
      }),
    );
  }

  removeShippyProError(operationLabelInfo: OperationLabelInfoModel): Observable<any> {
    return this.http.put(this.environmentService.getRestEndpoint('removeShippyProError'), operationLabelInfo);
  }

  changeRowStatus(operationId: string, rowId: string, status: string): Observable<any> {
    return this.http.post(this.environmentService.getRestEndpoint('changeRowStatus'), {
      operationId,
      dataRowId: rowId,
      newStatus: status,
    });
  }

  pollOperation(operationId: string): Observable<any> {
    return this.http.post(this.environmentService.getRestEndpoint('manualPoll') + operationId, {});
  }
  sendOperation(operationId: string): Observable<any> {
    return this.http.post(this.environmentService.getRestEndpoint('sendOperation') + operationId, {});
  }
  moveOperationToNew(operationId: string): Observable<any> {
    return this.http.post(this.environmentService.getRestEndpoint('moveOperationToNew') + operationId, {});
  }

  exportOperation(id: string): Observable<HttpResponse<any>> {
    return this.http.get(this.environmentService.getRestEndpoint('exportOperation') + id, {
      observe: 'response',
      responseType: 'blob' as 'text',
    });
  }

  exportOperationNew(id: string): Observable<{ url: string; fileName: string }> {
    return this.http
      .get(this.environmentService.getRestEndpoint('exportOperation') + id, { observe: 'response', responseType: 'blob' as 'text' })
      .pipe(
        map((r) => {
          const url = URL.createObjectURL(new Blob([r.body]));
          const contentDisposition = r.headers.get('Content-Disposition');
          const fileName = contentDisposition.split(';')[1].split('filename')[1].split('=')[1].trim();
          return { url, fileName };
        }),
      );
  }
}
